<template>
  <div class="swiper-container" ref="cur">
    <div class="swiper-wrapper">
      <div class="swiper-slide swiper_div" v-for="(item, i) in list" :key="i">
        <div class="swiper_div_head">
          <span>解决方案</span>
          <div class="head_text">
            <h2>{{ item.title }}</h2>
            <span>{{ item.text }}</span>
          </div>
        </div>
        <div class="swiper_div_nav">
          <div class="nav_img">
              <img :src="item.pc_images" alt="" />
          </div>
          <ul class="all_picScroll_top">
            <li v-for="(el, i) in item.imgList" :key="i">
              <img :src="el.pc_images" alt="" />
              <h2>
                <span>{{ el.name }}</span>
              </h2>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//引入Swiper
import Swiper from 'swiper'
export default {
  name: 'zzCarousel',
  props: {
    list: {
      type: Array,
      default: []
    },
    status: {
      type: Number,
      default: 0
    }
  },
  watch: {
    list: {
      //为什么即使监听到数据变化了，还需要放入$nextTick？因为v-for渲染需要时间，在没有渲染完成之前，不能初始化swiper
      immediate: true,
      handler () {
        let that = this
        //只能监听到数据已经有了，但是v-for动态渲染结构我们还是没有办法确定的，因此还是需要用nextTick
        that.$nextTick(() => {
          var mySwiper1 = new Swiper(that.$refs.cur, that.data)
          that.$emit('currentIndex', mySwiper1, 0)
          mySwiper1.on('transitionStart', function () {
            that.$emit('currentIndex', mySwiper1, mySwiper1.activeIndex)
          })
          mySwiper1.autoplay.stop() // 停止自动轮播
        })
      }
    }
  },
  data () {
    return {
      data: {
        direction: 'horizontal' /*横向滑动*/,
        loop: this.list.length > 5,
        autoplay: {
          disableOnInteraction: false, // 用户操作swiper之后，是否禁止autoplay （没有加入之前轮播图设置 autoplay：true无效）
          delay: 5000 // 自动切换的时间间隔（单位ms）
        },
        observeParents: false, //必须加入
        observer: true //必须加入
      }
    }
  },
  methods: {},
  mounted () {}
}
</script>
<style scoped lang="less">
.slide-list {
  width: 86%;
  margin: 0 auto;
  margin-top: 20px;
  position: relative;
  overflow: hidden;
  outline: none;
  display: flex;
}
.swiper_div {
  .swiper_div_head {
    margin: 40px 0;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    span {
      display: inline-block;
      width: 200px;
      line-height: 2.4;
      border: 1px solid #c2192e;
      box-sizing: border-box;
      color: #c2192e;
      text-align: center;
      font-size: 26px;
    }
    .head_text {
      width: 100%;
      max-width: calc(100% - 240px);
      text-align: left;
      h2 {
        font-size: 16px;
        color: #c2192e;
      }
      span {
        display: inline-block;
        margin: 10px 0 0;
        color: #666;
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
  .swiper_div_nav {
    display: flex;
    justify-content: space-between;
    .nav_img {
      width: 900px;
      height: 505px;
      overflow: hidden;
      img {
        display: block;
        width: 100%;
        height: auto;
      }
    }
    ul {
      width: 270px;
      height: 505px;
      overflow: auto;
      overflow-x: hidden;
      li {
        margin-top: 24px;
        img {
          width: 100%;
        }
        h2 {
          position: absolute;
          top: 0;
          height: 0;
          left: 0;
          transition: height 0.5s cubic-bezier(0.52, 1.64, 0.37, 0.66);
          text-align: center;
          width: 100%;
          overflow: hidden;
          span {
            font-style: normal;
            color: #fff;
            font-size: 14px;
            border-top: 1px solid #c8c5c4;
            border-bottom: 1px solid #c8c5c4;
            line-height: 20px;
            width: 140px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 50px 0 10px;
            height: 20px;
            position: absolute;
            left: 50%;
            margin-left: -70px;
            top: 50%;
            margin-top: -41px;
          }
        }
      }
      li:hover h2 {
        height: 100%;
      }
      li:nth-child(1) {
        margin-top: 0;
      }
    }
    ul::-webkit-scrollbar {
      width: 6px;
      height: 1px;
    }
    ul::-webkit-scrollbar {
      width: 0 !important;
    }
    ul::-webkit-scrollbar {
      width: 0 !important;
      height: 0;
    }
  }
}
/deep/.swiper-slide {
  width: 100%;
}
.swiper-slide-img {
  width: 50%;
}
/deep/.swiper-container {
  position: relative;
}
.swiper-pagination {
  min-width: 1200px;
  display: flex;
  justify-content: center;
}
/deep/.swiper-pagination-bullets {
  bottom: 20px;
}
/deep/.my-swiper-pagination-bullet {
  width: 36px;
  height: 4px;
  opacity: 0.4;
  background: #fff;
  border-radius: 50px;
  cursor: pointer;
  margin: 0 5px;
}
/deep/.my-bullet-active {
  background: #fff;
  cursor: pointer;
  opacity: 1;
}
/deep/.swiper-container-horizontal > .swiper-pagination-bullets {
  left: 0;
  width: 100%;
}
</style>
