<template>
  <div class="zz_index2">
    <SwiperCom :list="lbImgs" class="zz_Carousel"> </SwiperCom>
    <Carousel :list="lbImgs2" class="zz_Carousel2"> </Carousel>
    <div class="index2_section current_top">
      <div class="index2_section_nav">
        <div class="nav_title">
          <h4>
            <span class="current_color">一站式配套</span>
            解决方案服务
          </h4>
          <p>{{ peitaoData.peitaoText }}</p>
        </div>
        <ul class="nav_clearfix">
          <li v-for="(item, idx) in peitaoData.peitaoList" :key="idx">
            <h2>{{ item.key }}</h2>
            <div class="li_num">
              <span>{{ item.value }}</span>
              {{ idx == 0 ? '年' : idx == 1 ? '平方' : idx == 2 ? '多' : '家' }}
            </div>
            <h3>{{ peitaoData.peitaoList2[idx].key }}</h3>
            <p>{{ peitaoData.peitaoList2[idx].value }}</p>
          </li>
        </ul>
        <a href="#/zz_programme2" class="all_more" @click="toLink(75)">立即前往</a>
      </div>
    </div>
    <div class="index2_cpztc current_top">
      <div class="zz2_all_title">
        <h2><span class="current_color">某某厨具</span> 产品直通车</h2>
      </div>
      <div class="index2_cpztc_nav" v-if="cpzxList.length != 0">
        <div class="cpztc_nav_head">
          <h2>产品推荐</h2>
          <a href="#/zz_product2" @click="toLink(74)">查看更多产品</a>
        </div>
        <ul class="cpztc_nav_bottom">
          <li v-for="(item, idx) in cpzxList" :key="idx">
            <a :href="'#/zz_product2?currentIdx=' + 0 + '&currentId=' + item.id" @click="toLink(74)">
              <div class="li_img">
                <img :src="item.site_images" alt="" style="width:100%" />
                <div class="hover_div centerText">
                  <span>点击查看详情</span>
                </div>
              </div>
              <div class="li_text">{{ item.title }}</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="index2_dingzhi current_top">
      <div
        class="index2_dingzhi_img"
        :style="{ background: `url(${basicMsg.fanganImg}) no-repeat center 0` }"
      ></div>
      <ul class="index2_dingzhi_nav">
        <li
          v-for="(item, idx) in dzList"
          :key="idx"
          :style="{ backgroundImage: `url(${idx != 3 ? item.fanganBg : ''})` }"
        >
          <div class="container" v-if="idx % 2 === 0">
            <div class="container_left">
              <h2>
                <h4 :style="{ backgroundImage: `url(${item.fanganIcon})` }">
                  {{ item.fanganTitle }}
                </h4>
                <span>{{ item.fanganYw }}</span>
              </h2>
              <p>{{ item.fanganText }}</p>
            </div>
            <div class="container_right"></div>
          </div>
          <div class="container" v-else>
            <div class="container_right">
              <img :src="item.fanganBg" alt="" v-if="idx == 3" />
            </div>
            <div class="container_left">
              <h2>
                <h4 :style="{ backgroundImage: `url(${item.fanganIcon})` }">
                  {{ item.fanganTitle }}
                </h4>
                <span>{{ item.fanganYw }}</span>
              </h2>
              <p>{{ item.fanganText }}</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="index2_designer">
      <div class="index2_designer_title">某某厨房工程设计图</div>
      <ul class="index2_designer_nav">
        <li v-for="(item, idx) in cfsjList" :key="idx">
          <img :src="item" alt="" style="width:100%" />
        </li>
      </ul>
    </div>
    <div class="index2_cjxw">
      <div class="index2_cjxw_container">
        <div class="zz2_all_title">
          <h2><span class="current_color">某某厨具</span> 新闻中心</h2>
        </div>
        <ul class="container_nav">
          <li v-for="(item, idx) in cpxwList" :key="idx">
            <div class="li_title">
              <span class="xz_active_div">{{ item.name }}</span>
              <a :href="'#/zz_trends2?currentIdx=' + (idx + 1)">more >></a>
            </div>
            <div :class="[idx == 0 ? 'li_image' : '']">
              <span v-if="idx == 0">news</span>
              <a :href="'#/zz_trends2?currentIdx=' + (idx + 1)">
                <img
                  :src="item.icon_image"
                  alt=""
                  style="width: 100%;height: auto;"
                />
              </a>
            </div>
            <div class="li_list li_list0" v-if="idx == 0">
              <a
                v-for="(el, i) in item.news"
                :key="i"
                :href="
                  '#/zz_trends2?currentIdx=' + (idx + 1) + '&detailsId=' + el.id
                "
              >
                <div class="li_list_nr" v-if="i < 6">
                  <h2 class="ellipsis">{{ el.title }}</h2>
                  <p>{{ el.create_time }}</p>
                </div>
              </a>
            </div>
            <div class="li_list li_list1" v-if="idx == 1">
              <a
                v-for="(el, i) in item.news"
                :key="i"
                :href="
                  '#/zz_trends2?currentIdx=' + (idx + 1) + '&detailsId=' + el.id
                "
              >
                <div class="li_list_nr" v-if="i < 4">
                  <h2 class="ellipsis">{{ el.title }}</h2>
                  <p class="two_ellipsis">{{ el.miaoshu }}</p>
                </div>
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="index2_miaoshu">
      <div class="zz2_all_title">
        <h2>走近 <span class="current_color">某某厨具</span></h2>
      </div>
      <div
        class="index2_miaoshu_nav"
        :style="{ backgroundImage: `url(${basicMsg.basicImg})` }"
      >
        <div class="container">
          <div class="container_box">
            <h2>{{ basicMsg.basicTitle }}</h2>
            <p class="two_ellipsis">{{ basicMsg.basicText }}</p>
            <a href="#/zz_aboutuser2" @click="toLink(51)">走进我们</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Carousel from '@/common/Carousel/zzCarousel1.vue'
import Carousel2 from '@/common/Carousel/zzCarousel2.vue'
import SwiperCom from '@/common/Carousel/zzCarousel.vue'
import { getPageMsg, getXwData, getBasicMsg, getProductList } from '@/api/api'
export default {
  components: { Carousel, Carousel2, SwiperCom },
  data () {
    return {
      basicMsg: {},
      lbImgs: [],
      lbImgs2: [],
      searchName: '洗碗机',
      clearfixList: [
        {
          title: '研发设计团队',
          num: '16',
          unit: '年',
          name: '行业经验',
          text: '16年的行业经验，为客户提供精准的设计方案。'
        },
        {
          title: '强大生产基地',
          num: '10000',
          unit: '平方',
          name: '生产基地',
          text: '超10000平米的生产基地保质、保量、保准时。'
        },
        {
          title: '产品配套齐全',
          num: '8000',
          unit: '多',
          name: '种厨具设备',
          text: '8000多种厨具设备选购一站式厨房整体配套设计。'
        },
        {
          title: '千家客户案例',
          num: '1000',
          unit: '家',
          name: '客户案例见证',
          text: '上千家客户案例见证精心服务每位客户。'
        }
      ],
      peitaoData: {},
      cpzxList: [],
      dzList: [],
      cfsjList: [],
      cpxwList: [],
      active: 0,
      active2: 0,
      currentSwiper: {},
    }
  },
  methods: {
    getData () {
      let that = this
      getBasicMsg(that.changeData()).then(item => {
        if (item.data.code == 1) {
          let id = item.data.data.menu_id
          let project = item.data.data.project_id
          that.getPage(id)
          getXwData(item.data.data.project_id).then(res => {
            if (res.data.code == 1) {
              that.cpxwList = res.data.data
            }
          })
          getProductList(1, '', project).then(res => {
            if (res.data.code == 1) {
              console.log(res.data.data.list);
              that.cpzxList = res.data.data.list
            }
          })
        }
      })
    },
    getPage (i) {
      getPageMsg(i).then(res => {
        if (res.data.code == 1) {
          this.lbImgs = res.data.data.lunbo.pc_lunbo
          this.lbImgs2 = res.data.data.lunbo.js_lunbo
          this.peitaoData = res.data.data.peitao
          let list = []
          list.push(res.data.data.fangan)
          list.push(res.data.data.fangan2)
          list.push(res.data.data.fangan3)
          list.push(res.data.data.fangan4)
          list.forEach(el => {
            if (el.fanganBg2) {
              el.fanganBg = el.fanganBg2
              el.fanganIcon = el.fanganIcon2
              el.fanganText = el.fanganText2
              el.fanganTitle = el.fanganTitle2
              el.fanganYw = el.fanganYw2
            } else if (el.fanganBg3) {
              el.fanganBg = el.fanganBg3
              el.fanganIcon = el.fanganIcon3
              el.fanganText = el.fanganText3
              el.fanganTitle = el.fanganTitle3
              el.fanganYw = el.fanganYw3
            } else if (el.fanganBg4) {
              el.fanganBg = el.fanganBg4
              el.fanganIcon = el.fanganIcon4
              el.fanganText = el.fanganText4
              el.fanganTitle = el.fanganTitle4
              el.fanganYw = el.fanganYw4
            }
          })
          this.dzList = list
          this.basicMsg = res.data.data.basicMsg
          this.cfsjList = res.data.data.sheji.shejiImg
        }
      })
    },
    querySearch (queryString, cb) {
      var restaurants = this.restaurants
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter (queryString) {
      return restaurant => {
        return (
          restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) ===
          0
        )
      }
    },
    toLink(i){
      sessionStorage.setItem(this.changeData() + 'id', i)
    },
    handleSelect (item) {},
    currentIndex (e, i) {
      this.active = i
      this.currentSwiper = e
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style lang="less" scoped>
@import url('~@/assets/css/mixins.less'); //混合
.zz_index2 {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .zz_Carousel2 {
    display: none;
  }
  .index2_section {
    background: url(~@/assets/image/index/mapbg.jpg) no-repeat center 0;
    margin-bottom: 60px;
    .index2_section_nav {
      width: 1200px;
      margin: 0 auto;
      .nav_title {
        text-align: center;
        margin-bottom: 30px;
        h4 {
          font-size: 36px;
          font-weight: 600;
          margin: 0;
          span {
            color: #c2192e;
          }
        }
        h4:after {
          display: block;
          margin: 10px auto;
          height: 1px;
          width: 20px;
          background-color: #000;
          content: '';
        }
        p {
          margin: 0;
          font-size: 24px;
        }
      }
      .nav_clearfix {
        margin-bottom: 60px;
        display: flex;
        li {
          width: 25%;
          text-align: center;
          font-size: 14px;
          h2 {
            line-height: 3;
            font-weight: 600;
          }
          .li_num {
            font-size: 14px;
            span {
              font-weight: 600;
              font-size: 40px;
              padding-right: 5px;
            }
          }
          h3 {
            color: #999;
          }
          p {
            margin: 20px 0 0;
            padding: 0 50px;
            line-height: 1.2;
          }
        }
      }
      ul:after {
        display: table;
        line-height: 0;
        content: '';
      }
    }
  }
  .index2_cpztc {
    width: 1200px;
    margin: 0 auto;
    .index2_cpztc_nav {
      .cpztc_nav_head {
        margin-top: 20px;
        border-bottom: 1px solid #ddd;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        h2 {
          display: inline-block;
          font-size: 24px;
          padding-bottom: 10px;
          border-bottom: 1px solid #cb0004;
          box-sizing: border-box;
        }
        a {
          font-size: 14px;
          display: flex;
          align-items: center;
        }
        a:hover {
          color: #4d85ff;
        }
      }
      .cpztc_nav_bottom {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        li {
          width: 23%;
          margin-top: 18px;
          .li_text {
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 7px 0;
            border-bottom: 1px solid rgb(221, 221, 221);
          }
          .li_img {
            width: 80%;
            height: 200px;
            margin: 0 auto;
            position: relative;
            img {
              max-width: 100%;
              height: 100%;
              width: auto;
              display: inline-block;
              vertical-align: middle;
            }
            .hover_div {
              position: absolute;
              top: 0px;
              height: 0px;
              left: 0px;
              transition: height 0.5s cubic-bezier(0.52, 1.64, 0.37, 0.66) 0s;
              background: url(~@/assets/image/index/bg01.png) repeat;
              text-align: center;
              width: 100%;
              overflow: hidden;
              span {
                display: inline-block;
                font-style: normal;
                color: rgb(255, 255, 255);
                font-size: 14px;
                background: url(~@/assets/image/index/ico05.png) center 15px
                  no-repeat;
                border-top: 1px solid rgb(200, 197, 196);
                border-bottom: 1px solid rgb(200, 197, 196);
                width: 140px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding: 50px 0px 10px;
                height: 20px;
              }
            }
          }
          a:hover {
            .li_text {
              border-color: rgb(194, 25, 46);
              color: rgb(194, 25, 46);
            }
            .li_img > .hover_div {
              height: 100%;
            }
          }
        }
      }
    }
  }
  .index2_dingzhi {
    min-width: 1200px;
    .index2_dingzhi_img {
      height: 200px;
    }
    .index2_dingzhi_nav {
      li {
        background: center 0px no-repeat;
        .container {
          width: 1200px;
          margin: 0px auto;
          .container_left {
            font-size: 14px;
            display: inline-block;
            padding: 65px 47px 0px 56px;
            width: 497px;
            background-color: rgb(249, 249, 249);
            height: 355px;
            margin: 0px;
            vertical-align: top;
            h2 {
              margin: 0px 0px 14px;
              background: url(~@/assets/image/gif/line1.gif) 0px bottom
                no-repeat;
              padding-bottom: 20px;
              h4 {
                display: block;
                padding: 62px 0px 0px 22px;
                background: 0px 0px no-repeat;
                font-size: 26px;
                color: rgb(194, 25, 46);
              }
              span {
                display: block;
                line-height: 30px;
                font-size: 14px;
                color: rgb(203, 203, 203);
                text-transform: uppercase;
              }
            }
            p {
              background: url(~@/assets/image/gif/ico06.gif) 0px 10px no-repeat;
              padding: 0px 0px 10px 28px;
              margin: 0px;
              line-height: 2;
            }
          }
          .container_right {
            display: inline-block;
            width: 50%;
            height: 420px;
            vertical-align: top;
          }
        }
      }
    }
  }
  .index2_designer {
    padding: 60px 0;
    .index2_designer_title {
      background: url(~@/assets/image/gif/teamtbg.gif) center center no-repeat;
      height: 64px;
      line-height: 1.7;
      text-align: center;
      color: rgb(255, 255, 255);
      font-size: 32px;
      margin-bottom: 30px;
    }
    .index2_designer_nav {
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      li {
        width: 24%;
      }
    }
  }
  .index2_cjxw {
    padding: 60px 0;
    background-color: #f7f7f7;
    .index2_cjxw_container {
      width: 1200px;
      margin: 0 auto;
      .container_nav {
        display: flex;
        justify-content: space-between;
        li {
          width: 550px;
          .li_title {
            border-bottom: 1px solid rgb(221, 221, 221);
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            span {
              padding: 20px 0;
              display: inline-block;
              font-size: 18px;
              vertical-align: top;
              cursor: pointer;
            }
            a {
              padding: 20px 0;
              text-transform: uppercase;
            }
          }
          .li_image {
            height: 140px;
            padding-left: 162px;
            overflow: hidden;
            position: relative;
            span {
              position: absolute;
              width: 161px;
              height: 140px;
              left: 0px;
              top: 0px;
              text-align: center;
              line-height: 140px;
              color: rgb(255, 255, 255);
              font-weight: bold;
              background: url(~@/assets/image/gif/newtbg.gif) 0px 0px no-repeat;
              font-size: 28px;
              text-transform: uppercase;
            }
            a {
              max-width: calc(100% - 160px);
            }
          }
          .li_list {
            margin-top: 20px;
            .li_list_nr {
              font-size: 14px;
            }
          }
          .li_list0 {
            .li_list_nr {
              margin-top: 10px;
              width: 100%;
              display: flex;
              justify-content: space-between;
              h2 {
                color: #333333;
              }
              p {
                color: #999999;
              }
            }
          }
          .li_list1 {
            display: flex;
            flex-wrap: wrap;
            margin-top: 0;
            a {
              width: 50%;
            }
            .li_list_nr {
              width: 100%;
              border-bottom: 1px solid #ddd;
              box-sizing: border-box;
              display: block;
              padding: 15px 15px 10px 25px;
              background: url(~@/assets/image/gif/faq_ico1.gif) no-repeat 0 14px;
              h2 {
                color: #333;
                font-weight: 600;
              }
              p {
                margin: 5px 0 0;
                line-height: 1.5;
                color: #777;
              }
            }
            .li_list_nr:hover h2 {
              color: rgb(194, 25, 46);
            }
          }
        }
      }
    }
  }
  .index2_miaoshu {
    margin-bottom: 60px;
    .zz2_all_title {
      display: none;
    }
    .index2_miaoshu_nav {
      background: no-repeat center 0;
      padding-top: 520px;
      .container {
        width: 1200px;
        margin: 0 auto;
        .container_box {
          padding: 30px 60px;
          background-color: #fff;
          box-shadow: 0 0 6px #e9e9e9;
          font-size: 14px;
          text-align: center;
          h2 {
            margin: 0 0 20px;
            color: #c2192e;
            font-size: 20px;
            font-weight: 600;
          }
          p {
            margin: 0 0 20px;
            line-height: 2;
          }
          a {
            width: 170px;
            line-height: 40px;
            color: #fff;
            background-color: #c2192e;
            text-align: center;
            display: block;
            border-radius: 20px;
            margin: 0 auto;
          }
        }
      }
    }
  }
  .current_top {
    padding-top: 60px;
  }
  .current_color {
    color: #c2192e;
  }
  .on_style {
    span {
      color: #e96262;
    }
  }
  .xz_active_div {
    border-bottom: 2px solid #c2192e;
    color: rgb(194, 25, 46);
  }
  .all_clearfix:before,
  .all_clearfix:after {
    display: table;
    line-height: 0;
    content: '';
  }
}
</style>
